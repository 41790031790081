import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Descope, useSession } from '@descope/react-sdk';
import Header from './shared/Header';
import Footer from './shared/Footer';
import { toast } from 'react-toastify';

const AuthPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { isAuthenticated, isSessionLoading } = useSession();
  // const isSignUp = searchParams.get('flow') === 'signup';

  const redirectUrl = process.env.NODE_ENV === 'development' 
    ? 'http://localhost:3000/auth'
    : 'https://databackfill.com/auth';

  useEffect(() => {
    const code = searchParams.get('code');
    const flow = searchParams.get('descope-login-flow');
    
    if (code && flow) {
      console.log('Received OAuth callback with code:', code);
      
      if (!isAuthenticated) {
        localStorage.removeItem('sessionToken');
      }
      
      if (isAuthenticated) {
        console.log('Successfully authenticated after OAuth callback');
        navigate('/dashboard');
      }
    } else if (isAuthenticated && !isSessionLoading) {
      navigate('/dashboard');
    }
  }, [isAuthenticated, isSessionLoading, navigate, searchParams]);

  const handleSuccess = async (e) => {
    console.log(e);
    // return;
    try {
      console.log('Authentication successful:', {
        userId: e.detail.user.userId,
        email: e.detail.user.email
      });
      
      const sessionToken = e.detail.sessionJwt;
      if (sessionToken) {
        localStorage.setItem('sessionToken', sessionToken);
        localStorage.setItem('createdTime', e.detail.user.createdTime);
        console.log('Session token stored');
        
        // toast.success(isSignUp ? 'Successfully signed up!' : 'Successfully logged in!');
        navigate('/dashboard');
      } else {
        console.error('No session token received');
        toast.error('Authentication failed. Please try again.');
      }
    } catch (error) {
      console.error('Error in handleSuccess:', error);
      toast.error('Something went wrong. Please try again.');
    }
  };

  const handleError = (error) => {
    console.error('Authentication Error:', error);
    toast.error('Authentication failed. Please try again.');
  };

  if (isSessionLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <Header />
      
      <div className="pt-20">
        <div className="max-w-2xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
          <div className="bg-white p-8 rounded-2xl shadow-sm border border-gray-200">
            <div className="mb-8 text-center">
              <h1 className="text-2xl font-bold text-gray-900 mb-2">
                {/* {isSignUp ? 'Create your account' : 'Welcome back'} */}
                Welcome!
              </h1>
              <p className="text-gray-600">
                {/* {isSignUp ? 'Get started with DataBackfill' : 'Sign in to your account'} */}
                Get started with DataBackfill
              </p>
            </div>

            <div className="w-full">
              <Descope
                flowId="sign-up-or-in"
                theme="light"
                onSuccess={handleSuccess}
                onError={handleError}
                telemetryDisabled={true}
                redirectUrl={redirectUrl}
                persistence={true}   
                autoRefresh={true}
              />
            </div>

            <p className="mt-8 text-sm text-center text-gray-600">
              By continuing, you agree to our{' '}
              <a href="/terms" className="text-blue-600 hover:text-blue-700">
                Terms of Use
              </a>{' '}
              and{' '}
              <a href="/privacypolicy" className="text-blue-600 hover:text-blue-700">
                Privacy Policy
              </a>
            </p>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default AuthPage;