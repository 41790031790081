import React from 'react';
import { Link } from 'react-router-dom';
import { Mail, Users, Shield, Lightbulb, ArrowRight } from 'lucide-react';
import Header from './shared/Header';
import Footer from './shared/Footer';

const AboutPage = () => {
  return (
    <div className="min-h-screen bg-gray-50">
      <Header />

      {/* Main Content */}
      <div className="pt-20"> 
        {/* Hero Section */}
        <div className="bg-white border-b border-gray-200">
          <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="py-8 md:py-12">
              <h1 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4 md:mb-6 text-center sm:text-left">
                Welcome to DataBackfill
              </h1>
              <p className="text-lg md:text-xl text-gray-600 leading-relaxed">
                At DataBackfill, our mission is to make wrangling your GA4 data a breeze and transform it into actionable insights—without the headaches. 
                We believe every business, big or small, should have effortless access to their historical GA4 data in BigQuery, enabling them to unlock 
                competitive advantages, cut through complexity, and get straight to the good stuff: meaningful analytics that drive results.
              </p>
            </div>
          </div>
        </div>

        {/* Main Content Sections */}
        <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 py-8 md:py-12">
          <div className="space-y-8 md:space-y-16">
            {/* Our Story Section */}
            <section>
              <div className="bg-white rounded-2xl shadow-sm border border-gray-200 p-6 md:p-8">
                <h2 className="text-xl md:text-2xl font-bold text-gray-900 mb-6">Our Story</h2>
                <div className="bg-blue-50 rounded-xl p-4 md:p-6 mb-6">
                  <span className="text-blue-600 font-medium">Founded in 2024</span>
                </div>
                <p className="text-gray-600 leading-relaxed">
                  DataBackfill was created to solve a critical gap in data management and analytics integration. 
                  Frustrated by the limitations of existing tools and complex GA4 data backfilling processes, 
                  our team of data experts and software engineers set out to build an intuitive solution for 
                  data-driven companies.
                </p>
              </div>
            </section>

            {/* Mission and Vision */}
            <section>
              <div className="bg-white rounded-2xl shadow-sm border border-gray-200 p-6 md:p-8">
                <h2 className="text-xl md:text-2xl font-bold text-gray-900 mb-6">Our Mission and Vision</h2>
                <p className="text-gray-600 leading-relaxed mb-6">
                  Our mission is to transform how data is managed, analyzed, and leveraged by making complex 
                  tasks accessible and streamlined for everyone. We envision a world where businesses of all 
                  sizes can gain unmatched insights and competitive advantages from their data without 
                  unnecessary roadblocks or technical bottlenecks.
                </p>
              </div>
            </section>

            {/* Core Values */}
            <section>
              <h2 className="text-xl md:text-2xl font-bold text-gray-900 mb-6">Core Values</h2>
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-6">
                <div className="bg-white rounded-xl shadow-sm border border-gray-200 p-6">
                  <div className="w-12 h-12 bg-blue-50 rounded-xl flex items-center justify-center mb-4">
                    <Users className="text-blue-600 w-6 h-6" />
                  </div>
                  <h3 className="font-semibold text-gray-900 mb-2">User-Centric Design</h3>
                  <p className="text-gray-600 text-sm">
                    We prioritize ease of use and focus on building tools that are both powerful and user-friendly.
                  </p>
                </div>
                <div className="bg-white rounded-xl shadow-sm border border-gray-200 p-6">
                  <div className="w-12 h-12 bg-purple-50 rounded-xl flex items-center justify-center mb-4">
                    <Shield className="text-purple-600 w-6 h-6" />
                  </div>
                  <h3 className="font-semibold text-gray-900 mb-2">Security First</h3>
                  <p className="text-gray-600 text-sm">
                    Your data is valuable. We adhere to the highest standards of data protection and privacy.
                  </p>
                </div>
                <div className="bg-white rounded-xl shadow-sm border border-gray-200 p-6">
                  <div className="w-12 h-12 bg-green-50 rounded-xl flex items-center justify-center mb-4">
                    <Lightbulb className="text-green-600 w-6 h-6" />
                  </div>
                  <h3 className="font-semibold text-gray-900 mb-2">Innovation</h3>
                  <p className="text-gray-600 text-sm">
                    We are committed to continual improvement and staying ahead of emerging trends in data analytics and integration.
                  </p>
                </div>
              </div>
            </section>

            {/* Why Choose Us */}
            <section>
              <div className="bg-gradient-to-br from-blue-500 to-blue-600 rounded-2xl shadow-lg p-6 md:p-8 text-white">
                <h2 className="text-xl md:text-2xl font-bold mb-4 md:mb-6">Why Choose Us?</h2>
                <p className="leading-relaxed mb-6 md:mb-8 text-blue-50">
                  We're not just another software provider. Our solution is built by industry experts who 
                  understand the challenges of modern data teams. We continuously listen to feedback, adapt, 
                  and innovate to provide unmatched value to our users.
                </p>
                <Link 
  to="/pricing"
  onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
  className="inline-flex items-center bg-white text-blue-600 px-4 md:px-6 py-2 md:py-3 
             rounded-xl font-medium hover:bg-blue-50 transition-colors"
>
  Start Backfilling
  <ArrowRight className="ml-2 w-4 h-4" />
</Link>
              </div>
            </section>

            {/* Contact Section */}
            <section>
              <div className="bg-white rounded-2xl shadow-sm border border-gray-200 p-6 md:p-8 text-center">
                <div className="w-12 h-12 bg-blue-50 rounded-full flex items-center justify-center mx-auto mb-4">
                  <Mail className="text-blue-600 w-6 h-6" />
                </div>
                <h2 className="text-xl md:text-2xl font-bold text-gray-900 mb-4">Get in Touch</h2>
                <p className="text-gray-600 mb-6">
                  Have questions or want to learn more about our journey?
                </p>
                <a 
                  href="mailto:support@DataBackfill.com" 
                  className="text-blue-600 hover:text-blue-700 font-medium inline-block"
                >
                  support@DataBackfill.com
                </a>
              </div>
            </section>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default AboutPage;