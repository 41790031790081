import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import apiClient from '../utils/apiClient';
import { toast } from 'react-toastify';

const OAuthPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  // Determine the correct redirect URL based on environment
  const redirectUrl = process.env.NODE_ENV === 'development' 
    ? 'http://localhost:3000/auth'
    : 'https://databackfill.com/auth';

  useEffect(() => {
    const oauthSubmit = async () => {
  
      try {
        const response = await apiClient.get('/oauth2callback');
  
        if (response.redirect_url) {
          window.location.href = response.redirect_url;
        }
      } catch (error) {
        console.error('Setup error:', error);
      } finally {
      }
    };
    oauthSubmit();

    const code = searchParams.get('code');
    const flow = searchParams.get('descope-login-flow');
    
    if (code && flow) {}
  }, []);


  const handleError = (error) => {
    console.error('Authentication Error:', error);
    toast.error('Authentication failed. Please try again.');
  };

    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
      </div>
    );

};

export default OAuthPage;