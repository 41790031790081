import { Link } from 'react-router-dom';
import React, { useEffect } from 'react';
import Header from './shared/Header';
import Footer from './shared/Footer';
import { 
  ArrowRight, 
  Database, 
  Lock, 
  Zap,
  ChevronRight
} from 'lucide-react';

const LandingPage = () => {
  // Avatar data
  const avatars = [
    { id: 1, type: 'women', number: 32, name: 'Sarah Chen' },
    { id: 2, type: 'men', number: 45, name: 'Michael Park' },
    { id: 3, type: 'women', number: 28, name: 'Emma Wilson' },
    { id: 4, type: 'men', number: 63, name: 'James Miller' }
  ];

  const features = [
    {
      icon: <Lock size={20} />,
      title: "Security First",
      description: "Enterprise-grade security protocols to protect your sensitive data",
      color: "purple"
    },
    {
      icon: <Zap size={20} />,
      title: "Real-time Processing",
      description: "Process millions of data points in real-time",
      color: "purple"
    }
  ];

  const integrations = [
    'Google Analytics 4',
    'BigQuery',
    'Looker',
    'Data Studio'
  ];


  return (
    <div className="min-h-screen bg-white overflow-hidden">
      <Header />
      
      {/* Hero Section */}
      <section className="pt-20 md:pt-32 pb-12 md:pb-24 relative">
        <div className="absolute inset-0 bg-gradient-to-br from-blue-50 via-white to-white" />
        <div className="max-w-[1200px] mx-auto px-4 sm:px-6 lg:px-8 relative">
          {/* Floating decorative elements */}
          <div className="absolute top-0 right-[10%] w-20 h-20 bg-blue-50 rounded-full blur-2xl" />
          <div className="absolute bottom-0 left-[20%] w-32 h-32 bg-purple-50 rounded-full blur-2xl" />
          
          <div className="grid grid-cols-1 lg:grid-cols-12 gap-8 items-center">
            {/* Left side content */}
            <div className="lg:col-span-6">
              <div className="mb-8">
                <span className="inline-block px-3 py-1 bg-blue-50 text-blue-600 rounded-full text-sm font-medium mb-4">
                  GA4 Data Sync
                </span>
                <h1 className="text-3xl md:text-5xl font-bold text-gray-900 leading-tight mb-6">
                  Unlock the Power of Historical GA4 Data
                </h1>
                <p className="text-lg md:text-xl text-gray-600 leading-relaxed">
                  Effortlessly backfill and sync your historical Google Analytics 4 data into BigQuery.
                </p>
              </div>

              <div className="flex flex-col sm:flex-row items-center gap-4">
              <Link 
  to="/pricing"
  onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
  className="w-full sm:w-auto px-6 py-3 bg-blue-500 hover:bg-blue-600 text-white rounded-xl 
           font-medium transition-all shadow-lg hover:shadow-xl hover:translate-y-[-1px] 
           flex items-center justify-center"
>
  Start Backfilling
  <ArrowRight className="ml-2" size={18} />
</Link>
                {/* Avatar Group */}
                <div className="flex -space-x-2 overflow-hidden">
                  {avatars.map((avatar) => (
                    <img 
                      key={avatar.id}
                      src={`https://randomuser.me/api/portraits/thumb/${avatar.type}/${avatar.number}.jpg`}
                      alt={avatar.name}
                      title={avatar.name}
                      className="w-8 h-8 rounded-full border-2 border-white object-cover 
                               transform transition-transform hover:scale-110 hover:z-10 cursor-pointer"
                    />
                  ))}
                  <div className="w-8 h-8 rounded-full bg-blue-100 border-2 border-white 
                                flex items-center justify-center transform transition-transform 
                                hover:scale-110 hover:z-10 cursor-pointer">
                    <span className="text-blue-600 text-xs font-medium">+5</span>
                  </div>
                </div>
              </div>
            </div>

            {/* Right side with floating cards */}
            <div className="lg:col-span-6 relative min-h-[300px] md:min-h-[400px]">
              {/* Total Syncs Card */}
              <div className="absolute top-0 left-[10%] bg-white rounded-2xl shadow-lg p-6 w-64 transform rotate-3 hover:rotate-0 transition-transform">
                <div className="flex items-center space-x-4 mb-4">
                  <div className="w-12 h-12 bg-blue-50 rounded-xl flex items-center justify-center">
                    <Database className="text-blue-600" size={24} />
                  </div>
                  <div>
                    <div className="text-sm text-gray-600">Total Syncs</div>
                    <div className="text-2xl font-semibold">1,432</div>
                  </div>
                </div>
                <div className="h-2 bg-blue-100 rounded-full">
                  <div className="h-2 bg-blue-500 rounded-full w-3/4" />
                </div>
              </div>

              {/* Success Rate Card */}
              <div className="absolute bottom-0 right-[10%] bg-white rounded-2xl shadow-lg p-6 w-56 transform -rotate-2 hover:rotate-0 transition-transform">
                <div className="flex items-center justify-between mb-4">
                  <div className="text-sm font-medium">Success Rate</div>
                  <div className="text-green-500 font-medium">99.9%</div>
                </div>
                <div className="flex items-center space-x-2">
                  {[1, 2, 3, 4, 5].map((i) => (
                    <div key={i} className="h-8 w-2 bg-green-100 rounded-full">
                      <div className="h-6 w-2 bg-green-500 rounded-full" />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section className="py-12 md:py-20 relative">
        <div className="max-w-[1200px] mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col md:flex-row justify-between items-start md:items-end mb-8 md:mb-16">
            <div className="max-w-lg mb-4 md:mb-0">
              <h2 className="text-2xl md:text-3xl font-bold mb-4">Why Choose DataBackfill</h2>
              <p className="text-gray-600">Designed for modern data teams who need reliable analytics infrastructure</p>
            </div>
            <Link 
  to="/features" 
  onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
  className="text-blue-600 hover:text-blue-700 flex items-center"
>
  Explore all features
  <ChevronRight size={20} />
</Link>
          </div>

          {/* Feature Cards Grid */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 md:gap-8">
            <div className="col-span-2 bg-blue-50 rounded-3xl p-6 md:p-8 relative overflow-hidden">
              <div className="absolute top-0 right-0 w-64 h-64 bg-blue-100 rounded-full transform translate-x-1/2 -translate-y-1/2 blur-2xl" />
              <div className="relative">
                <h3 className="text-xl font-semibold mb-4">Complete Data Sync</h3>
                <p className="text-gray-600 mb-6">Easily backfill all historical GA4 data into BigQuery for unmatched insights.</p>
                <div className="flex flex-col sm:flex-row gap-4">
                  <div className="bg-white rounded-xl p-4 shadow-sm flex-1">
                    <div className="text-sm text-gray-600 mb-1">Properties</div>
                    <div className="text-2xl font-semibold">2.4k</div>
                  </div>
                  <div className="bg-white rounded-xl p-4 shadow-sm flex-1">
                    <div className="text-sm text-gray-600 mb-1">Data Points</div>
                    <div className="text-2xl font-semibold">14M</div>
                  </div>
                </div>
              </div>
            </div>

            {/* Smaller feature cards */}
            <div className="space-y-6">
              {features.map((feature, index) => (
                <div key={index} className={`bg-${feature.color}-50 rounded-3xl p-6`}>
                  <div className={`w-10 h-10 bg-${feature.color}-100 rounded-xl flex items-center justify-center mb-4`}>
                    <div className={`text-${feature.color}-600`}>{feature.icon}</div>
                  </div>
                  <h3 className="text-lg font-semibold mb-2">{feature.title}</h3>
                  <p className="text-gray-600 text-sm">{feature.description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      {/* How It Works Section */}
      <section className="py-12 md:py-20 bg-gray-50">
        <div className="max-w-[1200px] mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-8 md:mb-16">
            <h2 className="text-2xl md:text-3xl font-bold text-gray-900 mb-4">How It Works</h2>
            <p className="text-gray-600">Three simple steps to unlock your historical GA4 data</p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 max-w-4xl mx-auto">
            {/* Step 1 */}
            <div className="bg-white rounded-2xl shadow-sm p-6 md:p-8 relative">
              <div className="text-blue-500 font-medium mb-4">01</div>
              <h3 className="text-xl font-semibold mb-2">Connect GA4</h3>
              <p className="text-gray-600 text-sm">Enter your GA4 Property ID and date range</p>
            </div>

            {/* Step 2 */}
            <div className="bg-white rounded-2xl shadow-sm p-6 md:p-8 relative">
              <div className="text-blue-500 font-medium mb-4">02</div>
              <h3 className="text-xl font-semibold mb-2">Configure BigQuery</h3>
              <p className="text-gray-600 text-sm">Set up your BigQuery connection with our guided flow</p>
            </div>

            {/* Step 3 */}
            <div className="bg-white rounded-2xl shadow-sm p-6 md:p-8 relative">
              <div className="text-blue-500 font-medium mb-4">03</div>
              <h3 className="text-xl font-semibold mb-2">Start Syncing</h3>
              <p className="text-gray-600 text-sm">Watch as your historical data flows into BigQuery</p>
            </div>
          </div>
        </div>
      </section>

      {/* Integration Partners */}
      <section className="py-12 md:py-20 bg-gray-50">
        <div className="max-w-[1200px] mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-8 md:mb-16">
            <h2 className="text-2xl md:text-3xl font-bold mb-4">Seamless Integrations</h2>
            <p className="text-gray-600">Works with your favorite analytics tools</p>
          </div>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4 md:gap-8">
            {integrations.map((integration, index) => (
              <div key={index} className="bg-white rounded-2xl p-4 md:p-6 text-center shadow-sm hover:shadow-md transition-shadow">
                <div className="text-gray-900 font-medium">{integration}</div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-12 md:py-20">
        <div className="max-w-[1200px] mx-auto px-4 sm:px-6 lg:px-8">
          <div className="bg-blue-600 rounded-3xl p-6 md:p-12 relative overflow-hidden">
            <div className="absolute top-0 right-0 w-64 h-64 bg-blue-500 rounded-full transform translate-x-1/2 -translate-y-1/2 blur-2xl" />
            <div className="absolute bottom-0 left-0 w-64 h-64 bg-blue-700 rounded-full transform -translate-x-1/2 translate-y-1/2 blur-2xl" />

            <div className="relative max-w-lg mx-auto text-center">
              <h2 className="text-2xl md:text-3xl font-bold text-white mb-6">
                Ready to Transform Your Data Insights?
              </h2>
              <Link 
  to="/pricing"
  onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
  className="inline-flex items-center px-6 md:px-8 py-3 md:py-4 bg-white text-blue-600 
           rounded-xl text-base md:text-lg font-semibold hover:bg-blue-50 transition-all 
           shadow-lg hover:shadow-xl hover:translate-y-[-1px]"
>
  Start Backfilling Now
  <ChevronRight className="ml-2" size={20} />
</Link>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default LandingPage;