// App.js
import './styles/globals.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider as DescopeAuthProvider } from '@descope/react-sdk';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

// Import your components
import LandingPage from './components/LandingPage';
import DataSyncDashboard from './components/DataSyncDashboard';
import PricingPage from './components/PricingPage';
import Features from './components/Features';
import Product from './components/Product';
import SupportPage from './components/SupportPage';
import Documentation from './components/Documentation';
import PrivacyPolicy from './components/PrivacyPolicy';
import About from './components/About';
import AuthPage from './components/AuthPage';
import Terms from './components/Terms';
import PrivacyPopup from './components/PrivacyPopup';
import ContactPage from './components/ContactPage';
import ProtectedRoute from './components/shared/ProtectedRoute';
import OAuthPage from './components/OAuthPage';
import Success from './components/Success';
import Cancel from './components/Cancel';

// Configure axios defaults
axios.defaults.withCredentials = true;

const DESCOPE_PROJECT_ID = 'P2oie8UMzG2UrdgNptta6e6nf34a'
// const DESCOPE_PROJECT_ID = 'P2p3LzwpZP47mKe5PdaE3AFkYVtB';


function App() {
  return (
    <DescopeAuthProvider 
      projectId={DESCOPE_PROJECT_ID}
      onSuccessRedirect="/dashboard"
      onErrorRedirect="/auth"
      persistTokens={true}
      autoRefresh={true}
      
    >
      <Router>
        <Routes>
          {/* Public routes */}
          <Route path="/" element={<LandingPage />} />
          <Route path="/pricing" element={<PricingPage />} />
          <Route path="/success" element={<Success />} />
          <Route path="/cancel" element={<Cancel />} />
          <Route path="/features" element={<Features />} />
          <Route path="/product" element={<Product />} />
          <Route path="/documentation" element={<Documentation />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/about" element={<About />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/auth" element={<AuthPage />} />
          <Route path="/oauth2callback" element={<OAuthPage />} />
          <Route path="/support" element={<SupportPage />} />

          {/* Protected routes */}
          <Route element={<ProtectedRoute />}>
            <Route path="/dashboard" element={<DataSyncDashboard />} />
            
          </Route>
        </Routes>
        <PrivacyPopup />
      </Router>
      <ToastContainer />
    </DescopeAuthProvider>
  );
}

export default App;