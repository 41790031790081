import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';




const Cancel = () => {
    const navigate = useNavigate();
    
    const handleNavigate = ()=>{
        navigate('/dashboard');
    }
    return (
        <div className=" flex justify-center items-center h-screen">
            <div className=" bg-white overflow-hidden border-2 border-blue-500 w-[600px] rounded-xl max-w-[300px] mx-auto">
                <div
                    className="bg-white rounded-xl border-2 border-blue-500 p-6 md:p-8 flex flex-col 
                          relative shadow-lg transform md:scale-105 "
                >
                    {/* Popular badge */}
                    <div className=" text-center inline-block px-4 py-1 bg-blue-50 rounded-full text-red-600 font-medium text-sm mb-4">
                        Payment Failed....
                    </div>
                    <button
                        className="w-full py-2 px-4 bg-blue-600 hover:bg-blue-700 text-white rounded-lg 
                             text-sm font-medium mb-8 transition-colors max-w-[300px] mx-auto"
                             onClick={handleNavigate}
                    >
                        Go to Dashboard
                    </button>
                </div>

            </div>
        </div>
    )
}

export default Cancel