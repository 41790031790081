import React from 'react';

const DashboardHome = ({ data, paid, loading }) => {

  
  const getFormattedDate = (timestamp) => {
    const date = new Date(parseInt(timestamp) * 1000); // Multiply by 1000 to convert seconds to milliseconds

    // Format the date using toLocaleString or other formatting options
    const formattedTime = date.toLocaleString('en-US', {
      weekday: 'long',  // Full weekday name (e.g., "Monday")
      year: 'numeric',
      month: 'long',    // Full month name (e.g., "March")
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true      // Use 12-hour format (AM/PM)
    });

    return formattedTime;
  }

  const calcDifference = (timestamp) => {
    // Convert the string to a number and create a Date object for the timestamp
    const timestampDate = new Date(parseInt(timestamp) * 1000); // Convert seconds to milliseconds

    // Get the current date and time
    const now = new Date();

    // Calculate the difference in milliseconds
    const differenceInMilliseconds = now - timestampDate;

    // Convert the difference to hours and minutes
    const differenceInMinutes = Math.floor(differenceInMilliseconds / 60000); // Convert to minutes
    const differenceInHours = Math.floor(differenceInMinutes / 60);  // Convert to hours
    const remainingMinutes = differenceInMinutes % 60; // Get remaining minutes after hours
    if(differenceInHours > 0) {
      return `${differenceInHours} hours and ${remainingMinutes} minutes ago.`
    } else if(differenceInMinutes > 0){
      return `${remainingMinutes} minutes ago.`
    } else {
      return 'Just now.'
    }
  }

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <h1 className="text-xl md:text-2xl font-semibold">Dashboard Overview</h1>
      </div>
      {
        loading ? <div>Loading Data...</div>: 
        (paid ? 
        (
      !data || data.credential == 'none' ? 
        <div>Please register your google cloud account in Google Could tab.</div>
        :
        <div>
          {/* Stats Grid */}
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-4">
            <div className="bg-white p-4 md:p-6 rounded-lg border border-gray-200">
              <div className="text-sm text-gray-600">Total Syncs</div>
              {
                paid ? <div className="text-xl md:text-2xl font-semibold mt-2">{data?.totalSyncs || 0}</div>
                : <div className="text-xl md:text-2xl font-semibold mt-2">1,432 syncs completed</div>
              }
              
            </div>
            
            <div className="bg-white p-4 md:p-6 rounded-lg border border-gray-200">
              <div className="text-sm text-gray-600">Last Sync</div>
              <div className="text-xl md:text-2xl font-semibold mt-2">
                <p className='text-[15px] m-0'>
                  {  data.lastSync && data.lastSync.time ? getFormattedDate(data.lastSync.time) : 'No sure'} 
                </p>
                <p className='text-[20px] m-0'>
                  {data.lastSync && data.lastSync.status ? ( data.lastSync.status=='success' ? 'Completed Successfully!' : 'Failed.' ) : 'No syncs yet'}
                </p>
              </div>
            </div>
          </div>

          {/* Recent Activity */}
          <div className="bg-white rounded-lg border border-gray-200 mt-4">
            <div className="p-4 border-b border-gray-200">
              <h2 className="text-lg font-semibold">Recent Activity</h2>
            </div>
            <div className="p-4">
              {data?.recentActivity && data?.recentActivity.length > 0 ? (
                <div className="space-y-4">
                  {data?.recentActivity.map((activity, index) => (
                    <div key={index} className="flex flex-col sm:flex-row sm:justify-between sm:items-center">
                      <div className="mb-2 sm:mb-0">
                        
                        <p className="text-sm">GA4 Property ID {activity.property_id} - {activity.status} - {calcDifference(activity.datetime)}</p>
                      </div>
                      <div className="text-xs text-gray-400"></div>
                    </div>
                  ))}
                </div>
              ) : (
                <p className="text-gray-500 text-center py-4">No recent activity</p>
              )}
            </div>
          </div>
        </div>
        )
        : 
        <div>
          {/* Stats Grid */}
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-4">
            <div className="bg-white p-4 md:p-6 rounded-lg border border-gray-200">
              <div className="text-sm text-gray-600">Total Syncs</div>
                <div className="text-xl md:text-2xl font-semibold mt-2">1,432 syncs completed</div>
            </div>
            
            <div className="bg-white p-4 md:p-6 rounded-lg border border-gray-200">
              <div className="text-sm text-gray-600">Last Sync</div>
              <div className="text-xl md:text-2xl font-semibold mt-2">
                <p className='text-[15px] m-0'>
                March 1, 2024 - Successful
                </p>
                <p className='text-[20px] m-0'>
                Sample Data - Upgrade to see your stats
                </p>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-1 gap-4 mt-4">
            <div className="bg-white p-4 md:p-6 rounded-lg border border-gray-200">
              <div className="text-sm text-gray-600">Free tier</div>
                <div className="text-xl md:text-2xl font-semibold mt-2">Free tier: Limited to 60 days of historical data</div>
            </div>
          </div>

          {/* Recent Activity */}
          <div className="bg-white rounded-lg border border-gray-200 mt-4">
            <div className="p-4 border-b border-gray-200">
              <h2 className="text-lg font-semibold">Recent Activity</h2>
            </div>
            <div className="p-4">
              {data?.recentActivity && data?.recentActivity.length > 0 ? (
                <div className="space-y-4">
                  {data?.recentActivity.map((activity, index) => (
                    <div key={index} className="flex flex-col sm:flex-row sm:justify-between sm:items-center">
                      <div className="mb-2 sm:mb-0">
                        
                        <p className="text-sm">GA4 Property ID {activity.property_id} - {activity.status} - {calcDifference(activity.datetime)}</p>
                      </div>
                      <div className="text-xs text-gray-400"></div>
                    </div>
                  ))}
                </div>
              ) : (
                <p className="text-gray-500 text-center py-4">No recent activity</p>
              )}
            </div>
          </div>
        </div>)
      }
    </div>
  );
};

export default DashboardHome;